import axios from "axios";
import React, {useCallback, useEffect, useState} from "react";
import {Col, Row, Spinner} from "reactstrap";
import Index from "../../components/Main/auditorAndArtiste";
import UsersList from "./usersList";

function AuditorProAndArtistes() {

    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [userToShow, setUserToShow] = useState({});

    const checkLastUserSignIn = useCallback(async () => {
        !loading && setLoading(true)
        await axios.get('/admin/users/last').then((response) => {
            let data = response.data;
            if (data.length)
                setUserToShow(data[0])
            else setUserToShow({})
            setUsers(data)
            setLoading(false)
        })
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [])

    useEffect(() => {
        checkLastUserSignIn().then(() => null);
    }, [checkLastUserSignIn]);

    return (
        <div className="content">
            {loading ? (
                <div className="text-center mt-5">
                    <Spinner color="primary"/>
                    <p>Chargement des utilisateurs...</p>
                </div>
            ) : (
                <Row>
                    <Col md="4">
                        <UsersList
                            users={users}
                            setUsers={setUsers}
                            userToShow={userToShow}
                            setUserToShow={setUserToShow}
                            checkLastUserSignIn={checkLastUserSignIn}/>
                    </Col>
                    <Col md="8">
                        {Object.keys(userToShow).length !== 0
                            ? <Index data={userToShow}/>
                            : <p className="text-center">Aucun auditeur</p>
                        }
                    </Col>
                </Row>
            )}
        </div>
    );
}

export default AuditorProAndArtistes;
