import axios from "axios"
import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {Route, Routes, useNavigate} from "react-router-dom";
import AdminLayout from "./layouts/Admin";
import {addCountries} from "./reducer";
import Login from "./views/Login";
import "./assets/css/app.css";
import DecryptData from "./tools/DecryptData";

axios.interceptors.response.use(
    response => {
        return {data: DecryptData(response.data)};
    },
    error => {
        return Promise.reject(error);
    }
);

function App() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            axios.defaults.headers.common['admin'] = "true"
            axios.defaults.headers.common['Language'] = "fr"
            axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*"
            axios.defaults.headers.common['Content-Type'] = 'application/json'
            axios.get("countries_and_cities/all_countries").then(async r => {
                await dispatch(addCountries(r.data))
                axios.get("users/if_token_valid").then((resp) => {
                    navigate('/admin/dashboard');
                }).catch(() => {
                    localStorage.removeItem('creativeAdminToken')
                    navigate('/auth')
                });
            })
        }

        return () => {
            isMounted.current = true
        };
    }, [navigate, dispatch]);

    return (
        <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/auth" element={<Login />} />
            <Route path="/admin/*" element={<AdminLayout />} />
        </Routes>
    );
}

export default App;
