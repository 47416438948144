import dayjs from "dayjs";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Table} from "reactstrap";
import {copy, currencyByAddress} from "../../../tools/tools";

function BookingContactList(props) {

    const {contactCards} = props
    const [refIndexCopied, setRefIndexCopied] = useState(null);
    const countries = useSelector(state => state.global.countries);

    useEffect(() => {

    }, []);

    return (
        <Card className="card-plain m-2">
            <CardHeader>
                <p className="card-category">
                    Artiste(s) contacté
                </p>
                <hr/>
            </CardHeader>
            <CardBody>
                <Table responsive>
                    <thead className="text-primary">
                    <tr>
                        <th>Artiste</th>
                        <th>Contacté le</th>
                        <th className="text-left">Montant</th>
                        <th className="text-left">Reference</th>
                    </tr>
                    </thead>
                    {!contactCards?.length
                        ? ''
                        : <tbody>
                        {contactCards.map((contact, index) =>
                            <tr key={index}>
                                <td className="text-left">{contact?.reservation.artist_name}</td>
                                <td className="text-left">
                                    <small>{dayjs(contact?.created_at).format("DD-MM-YYYY à HH:MM")}</small>
                                </td>
                                <td className="text-left">
                                    {contact?.amount
                                        ? contact?.amount + currencyByAddress(contact.reservation.address, countries)
                                        : '*Offert'}
                                </td>
                                {contact?.payment
                                    ? <td className="text-left">
                                        <small>{contact?.payment.reference}&nbsp;</small>
                                        {refIndexCopied === contact.id ?
                                            <i className="nc-icon nc-check-2 text-success"/>
                                            : <i className="nc-icon nc-single-copy-04 cursor-pointer"
                                                 onClick={(e) =>
                                                     copy(
                                                         contact.id,
                                                         contact?.payment.reference,
                                                         setRefIndexCopied
                                                     )
                                                 }/>}
                                    </td> : null}
                            </tr>)}
                        </tbody>
                    }
                </Table>
            </CardBody>
        </Card>
    );
}

export default BookingContactList;
