import React from 'react';
import {Box, Grid, Paper, Typography} from '@mui/material';

function ChatMessage({avatar, side = 'left', messages = []}) {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: side === 'right' ? 'row-reverse' : 'row',
            alignItems: 'flex-start',
            mb: 2
        }}>
            {avatar && (
                <img
                    src={avatar}
                    alt="avatar"
                    style={{
                        width: 40,
                        height: 40,
                        borderRadius: '50%',
                        marginRight: side === 'right' ? '0' : '10px',
                        marginLeft: side === 'right' ? '10px' : '0',
                    }}
                />
            )}
            <Grid container spacing={1}>
                {messages.map((message, index) => (
                    <Grid item xs={12} key={index}>
                        <Paper
                            sx={{
                                p: 1.5,
                                backgroundColor: side === 'right' ? '#e3f2fd' : '#f5f5f5',
                                borderRadius: 2,
                            }}
                        >
                            <Typography variant="body2">{message}</Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default ChatMessage;
