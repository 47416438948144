import axios from "axios";
import React, {useState} from "react";
import {Input, Table} from "reactstrap";


function PaymentList({payments, setPaymentToShow, setPayments, paymentToShow, checkLastPayments}) {

    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

    const searchPayment = async (e) => {
        let str = e.target.value;
        setSearchTerm(str);
        if (str) {
            setLoading(true);
            await axios.get("/admin/payments/search/" + str).then((response) => {
                let data = response.data;
                if (data.length) setPaymentToShow(data[0]);
                else setPaymentToShow({});
                setPayments(data);
                setLoading(false);
            });
        } else {
            await checkLastPayments()
        }
    };

    const filteredPayments = payments.filter(
        (payment) =>
            payment.reference.toLowerCase().includes(searchTerm.toLowerCase()) ||
            payment.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="payment-list">
            {/* Barre de recherche */}
            <Input
                type="text"
                value={searchTerm}
                placeholder="Rechercher le paiement, référence, email, ..."
                onChange={(e) => searchPayment(e)}
                className="custom-input mb-3"
            />

            {/* Tableau des paiements */}
            <Table hover responsive className="custom-table">
                <thead>
                <tr>
                    <th>Référence</th>
                    <th>Email</th>
                </tr>
                </thead>
                <tbody>
                {filteredPayments.length === 0 ? (
                    <tr>
                        <td colSpan="3" className="text-center">
                            Aucun paiement trouvé
                        </td>
                    </tr>
                ) : (
                    filteredPayments.map((payment, index) => (
                        <tr
                            key={index}
                            className={`table-row ${
                                paymentToShow?.id === payment.id ? "selected-row" : ""
                            }`}
                            onClick={() => setPaymentToShow(payment)}
                        >
                            <td>{payment.reference.split('_')[1]}</td>
                            <td>{payment.email}</td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>
        </div>
    );
}

export default PaymentList;
