import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import axios from "axios";
import toast from "react-hot-toast";
import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {Button, CardBody, CardFooter, Col, Form, FormGroup, Input, InputGroup, Row, Spinner} from "reactstrap";
import {AmountField, changeFields, checkErrorMessage, ITextField} from "../../../tools/tools";
import CreditCard from "./creditCard";
import AmountInfos from "./AmountInfos";

function CreateContactCard(props) {
    const stripe = useStripe();
    const elements = useElements();
    const [city, setCity] = useState('');
    const [name, setName] = useState('');
    const [event, setEvent] = useState('');
    const [show, setShow] = useState(false);
    const [address, setAddress] = useState('');
    const [lastname, setLastName] = useState('');
    const [loading, setLoading] = useState(false);
    const [validate, setValidate] = useState(false);
    const [contactAmount, setContactAmount] = useState(0);
    const [cityShow, setCityShow] = useState(false);
    const [event_time, setEventTime] = useState('');
    const [postal_code, setPostalCode] = useState('');
    const [event_date, setEventDate] = useState('');
    const [total_amount, setTotalAmount] = useState(0);
    const [searchResult, setSearchResult] = useState([]);
    const [paymentIntent, setPaymentIntent] = useState({});
    const [showTravel, setShowTravel] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [travel_expenses, setTravelExpenses] = useState(0);
    const [t_travel_expenses, setTTravelExpenses] = useState(0);
    const [searchCityResult, setSearchCityResult] = useState([]);
    const {closeModal, service, serviceCountry} = props
    const [auditor_who_reserve_id, setAuditorWhoReserveId] = useState('');
    const [auditor_who_reserve_email, setAuditorWhoReserveEmail] = useState('');

    const searchUser = async (e) => {
        let str = e.target.value
        if (str) {
            setLoading(true)
            await axios.get('/admin/users/search/' + str).then((response) => {
                let data = response.data?.filter((u) => u?.id !== service?.user_id);
                setSearchResult(data)
                setLoading(false)
            })
        }
    }

    const addUser = (user) => {
        setShow(false)
        setName(user?.name)
        setAuditorWhoReserveId(user?.id)
        setAuditorWhoReserveEmail(user?.email)
    }

    const updateTravel = () => {
        if (service?.travel_expenses.from <= t_travel_expenses && t_travel_expenses <= service?.travel_expenses.to) {
            setShowTravel(false)
            setTravelExpenses(t_travel_expenses);
        } else {
            toast.error("Frais de transport non valable")
        }
    }

    const searchCity = (e) => {
        let city = e.target.value
        if (city) {
            axios.get("countries_and_cities/" + serviceCountry.id.toString() + '/' + city).then(async resp => {
                setSearchCityResult(resp.data);
            }).catch(() => console.log(''))
        }
    }

    const create = () => {
        let h = event_time.split(':')
        let t = event_date.split('-')
        let eventDateTime = new Date(
            parseInt(t[0]),
            parseInt(t[1]) - 1,
            parseInt(t[2]),
            parseInt(h[0]) + 1,
            parseInt(h[1]),
            0
        ).toISOString().slice(0, -1)

        let reservation = {
            status: 'pending',
            email: auditor_who_reserve_email,
            city: city,
            postal_code: postal_code,
            event: event,
            event_date: eventDateTime,
            phone: "",
            address: address,
            services_id: service.id,
            total_amount: total_amount,
            travel_expenses: travel_expenses,
            artist_owner_id: service.user_id,
            options_id_list: [],
            auditor_who_reserve_id: auditor_who_reserve_id
        };

        let contact_card_data = {
            'name': name,
            'lastname': lastname,
            'amount': contactAmount,
            'stripe_token': paymentIntent,
            'reservation': reservation
        }

        axios.post('/admin/contact_cards/assign_contact_card/new', contact_card_data).then((resp) => {
            toast.success("La prise de contact a été créer")
            closeModal()
        }).catch((error) => {
            let err = checkErrorMessage(error)?.message
            toast.error(err)
        })
    }

    const cardValidation = async () => {
        axios.get("wallet/createPaymentIntent/" + contactAmount.toFixed(2) + "/" + serviceCountry.currencies.code).then(async (resp) => {
            const payload = await stripe.confirmCardPayment(resp.data['clientSecret'], {
                payment_method: {
                    card: elements.getElement(CardElement),
                },
            });
            try {
                toast.error(payload.error.message)
            } catch (e) {
                setPaymentIntent(payload.paymentIntent)
                setPaymentModal(false)
            }
        })
    }

    const checkArtistContactAmount = async () => {
        axios.get("artist_conditions/condition/" + service.user_id).then(async (resp) => {
            setContactAmount(resp.data['contact_amount'])
        }).catch(async (error) => {
            let err = checkErrorMessage(error)?.message
            toast.error(err)
        })
    }

    useEffect(() => {

        checkArtistContactAmount().then(() => null)

        const transport = service?.travel_expenses
        const isBalanced = (transport?.from && transport?.to) || (!transport?.from && transport?.to);
        if (isBalanced) {
            let tTmp = (service?.travel_expenses.from + service?.travel_expenses.to) / 2
            setTravelExpenses(tTmp)
        } else if (service?.travel_expenses.from) {
            setTravelExpenses(service?.travel_expenses.from)
        }

        let serviceAmount = service?.price;
        setTotalAmount(serviceAmount);

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [service]);

    useEffect(() => {
        if (name && event_time && lastname && auditor_who_reserve_id && city && postal_code && event && event_date && address) {
            if (contactAmount && paymentIntent?.id) {
                setValidate(true)
            } else if (!contactAmount) {
                setValidate(true)
            }
        }
    }, [name, event_time, lastname, auditor_who_reserve_id, contactAmount, city, postal_code, event, event_date, address, paymentIntent?.id]);

    return (
        <CardBody>
            <Modal show={paymentModal} size="s" className="mt-2">
                <Modal.Header>
                    <h5 className="col text-light text-center">
                        Methode de payment&nbsp;
                    </h5>
                </Modal.Header>
                <Modal.Body className="text-light">
                    <CreditCard/>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-danger" onClick={() => setPaymentModal(false)}>
                        Fermer
                    </Button>
                    <Button className="btn-success" onClick={() => cardValidation()}>
                        Payer
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showTravel} size="s" className="mt-2">
                <Modal.Header className="text-light">
                    <h5 className="col text-light text-center">
                        Modifier le frais de transport&nbsp;
                        <small>(Entre&nbsp;
                            {service?.travel_expenses.from}&nbsp;{serviceCountry?.currencies?.symbol}
                            &nbsp;à&nbsp;{service?.travel_expenses.to}&nbsp;{serviceCountry?.currencies?.symbol})
                        </small>
                    </h5>
                </Modal.Header>
                <Modal.Body className="text-light">
                    <InputGroup className="no-border">
                        <Input
                            className="text-light"
                            placeholder={
                                "Entre "
                                + service?.travel_expenses.from + ' ' + serviceCountry?.currencies?.symbol
                                + ' à ' + service?.travel_expenses.to + ' ' + serviceCountry?.currencies?.symbol
                            } type='number'
                            value={t_travel_expenses}
                            onChange={(e) => setTTravelExpenses(parseInt(e?.target.value))}/>
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-danger" onClick={() => setShowTravel(false)}>
                        Fermer
                    </Button>
                    <Button className="btn-success" onClick={() => updateTravel()}>
                        Appliquer le changement
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show} size="m" className="mt-2" backdrop="static">
                <Modal.Header className="text-light">
                    <InputGroup className="no-border">
                        <Input
                            className="text-light"
                            placeholder="Chercher l'auditeur pour assigner la reservation"
                            onChange={(e) => searchUser(e)}/>
                    </InputGroup>
                </Modal.Header>
                <Modal.Body className="text-light">
                    <div className="text-center overflow-auto" style={{height: 200}}>
                        {loading
                            ? <Spinner animation="grow" size="m" className="mr-2"/>
                            : !searchResult.length
                                ? <p>Pas de resultat a afficher</p>
                                : searchResult.map((value, index) =>
                                    <Row key={index + 'search'}>
                                        <Col className="ml-auto mr-auto" lg="8" md="8" xs="8">
                                            <p>{value.email}</p>
                                        </Col>
                                        <Col className="mr-auto cursor-pointer" lg="4" md="4" xs="4">
                                            <i className="nc-icon nc-simple-add text-success"
                                               onClick={() => addUser(value)}/>
                                        </Col>
                                    </Row>
                                )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-danger" onClick={() => setShow(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={cityShow} size="s" className="mt-2">
                <Modal.Header className="text-light">
                    <InputGroup className="no-border">
                        <Input
                            className="text-light"
                            placeholder="Chercher la ville ou se déroule l'evenement"
                            onChange={(e) => searchCity(e)}/>
                    </InputGroup>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center overflow-auto" style={{height: 200}}>
                        {loading
                            ? <Spinner animation="grow" size="m" className="mr-2"/>
                            : !searchCityResult.length
                                ? <p>Pas de resultat a afficher</p>
                                : searchCityResult.map((value, index) =>
                                    <Row key={index}>
                                        <Col className="ml-auto mr-auto" lg="7" md="6" xs="6">
                                            <p>{value}</p>
                                        </Col>
                                        <Col className="mr-auto cursor-pointer" lg="2">
                                            <i className="nc-icon nc-simple-add text-success"
                                               onClick={() => {
                                                   setCityShow(false)
                                                   setCity(value)
                                               }}/>
                                        </Col>
                                    </Row>
                                )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-danger" onClick={() => setCityShow(false)}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>

            <Row>
                <Col md="6">
                    <h5 className="text-center mb-4">Details de la réservation</h5>
                    <Form>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <label>Evenement</label>
                                    <Input
                                        type="select"
                                        className="p-2"
                                        onChange={(e) => setEvent(e.target.value)}>
                                        <option value="">Pour quel evenement</option>
                                        {service?.events?.map((value, index) =>
                                            <option key={index + 1} value={value}>{value}</option>
                                        )}
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    <label>Date de l'événement</label>
                                    <Input
                                        value={event_date}
                                        placeholder="La date de l'evenement" type="date"
                                        onChange={(e) => changeFields(setEventDate, e)}/>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    <label>Heure</label>
                                    <Input
                                        value={event_time}
                                        placeholder="L'heure de l'événement"
                                        type="time"
                                        onChange={(e) => changeFields(setEventTime, e)}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        {(service?.travel_expenses.from && service?.travel_expenses.to)
                            ?
                            <Row className="px-2">
                                <small className="text-justify mb-1 bg-warning p-2 rounded">
                                    Le Frais de transport de cette prestation est une fourchette de&nbsp;
                                    {service?.travel_expenses.from}{serviceCountry?.currencies?.symbol}
                                    &nbsp;à&nbsp;{service?.travel_expenses.to}{serviceCountry?.currencies?.symbol}
                                    . Prédefini par le minimum au départ, soit&nbsp;{travel_expenses}
                                    {serviceCountry?.currencies?.symbol}.
                                </small>
                                <Button
                                    className="col secondary mt-2"
                                    onClick={() => setShowTravel(true)}>
                                    Modifier le frais de transport
                                </Button>
                            </Row> : ''}
                        <Row className="mt-2">
                            <Col md="8" className="text-break">
                                Frais de transport
                            </Col>
                            <Col md="4">
                                <p className="font-weight-bold text-right">
                                    {travel_expenses}&nbsp;{serviceCountry?.currencies?.symbol}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    {AmountField(
                                        "Montant de la reservation",
                                        total_amount,
                                        serviceCountry?.currencies?.symbol,
                                        (e) => changeFields(setTotalAmount, e),
                                        ""
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    {ITextField(
                                        "Adresse",
                                        null,
                                        address,
                                        (e) => changeFields(setAddress, e)
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Button style={{marginTop: '-1px', marginBottom: '1px'}}
                                            className={city ? "col btn-info" : "col secondary"}
                                            onClick={() => setCityShow(true)}>
                                        {city ? city : "Cherchez la ville de l'evenement"}
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    {ITextField(
                                        "Code Postale",
                                        null,
                                        postal_code,
                                        (e) => changeFields(setPostalCode, e)
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col md="6">
                    <h5 className="text-center mb-4">La prise de contact</h5>
                    <Form>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <label>Pour le compte de</label>
                                    <Button style={{marginTop: '-1px', marginBottom: 1}}
                                            className={auditor_who_reserve_email ? "col btn-info" : "col secondary"}
                                            onClick={() => setShow(true)}>
                                        {auditor_who_reserve_email ? auditor_who_reserve_email : "Cherchez l'auditeur PRO"}
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <FormGroup>
                                    {ITextField(
                                        "Nom",
                                        null,
                                        name,
                                        (e) => changeFields(setName, e)
                                    )}
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup>
                                    {ITextField(
                                        "Prénom",
                                        null,
                                        lastname,
                                        (e) => changeFields(setLastName, e)
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="8" className="text-break">
                                Prise de contact
                            </Col>
                            <Col md="4">
                                <p className="font-weight-bold text-right">
                                    {contactAmount
                                        ? contactAmount + serviceCountry?.currencies?.symbol
                                        : "Gratuit"}
                                </p>
                            </Col>
                        </Row>
                        <CardFooter style={{display: "contents"}}>
                            <Row className="flex-column">
                                <Col>
                                    <AmountInfos
                                        contactAmount={contactAmount}
                                        reservationAmount={total_amount}
                                        currency={serviceCountry?.currencies?.symbol}/>
                                    {contactAmount
                                        ? <Row>
                                            <Col md="12">
                                                <FormGroup>
                                                    <label>Methode de payment</label>
                                                    <Button style={{marginTop: '-1px', marginBottom: 1}}
                                                            className={auditor_who_reserve_email ? "col btn-success" : "col btn-warning"}
                                                            onClick={() => setPaymentModal(true)}>
                                                        {paymentIntent?.id
                                                            ? "Payment ID - " + paymentIntent?.id?.substring(0, 6) + " ***"
                                                            : "Ajouter un payment"}
                                                        <i className="nc-icon nc-credit-card ml-2"/>
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row> : null}
                                </Col>
                                <Col>
                                    <Row>
                                        <Col md="6">
                                            <Button
                                                color="danger"
                                                onClick={closeModal}>
                                                Fermer
                                            </Button>
                                        </Col>
                                        <Col md="6">
                                            <Button
                                                disabled={!validate}
                                                color="success"
                                                onClick={() => create()}>
                                                Enregister
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardFooter>
                    </Form>
                </Col>
            </Row>
        </CardBody>
    );
}

export default CreateContactCard;
