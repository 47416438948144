import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import toast from "react-hot-toast";
import {
    Card,
    CardBody,
    Input,
    InputGroup,
    InputGroupText,
    Spinner
} from "reactstrap";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, {useEffect, useRef, useState} from "react";
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import {axios_bot, changeFields, checkErrorMessage} from "../../../tools/tools";

function ManageData({tag, prefix}) {

    const isMounted = useRef(false);
    const [edit, setEdit] = useState(false);
    const [index, setIndex] = useState(null);
    const [text, setText] = useState('');
    const [newData, setNewData] = useState('');
    const [copied, setCopied] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const handleEdit = async (row, value) => {
        copy(value)
        setIndex(row)
        setEdit(true)
    };

    const getData = () => {
        let _axios = axios_bot();
        _axios.get('/bot/admin/' + prefix + '/' + tag).then((resp) => {
            setData(resp.data)
            setLoading(false)
        }).catch((error) => {
            let errorMessage = checkErrorMessage(error)?.message;
            toast.error(errorMessage)
        })
    }

    const addData = () => {
        let _axios = axios_bot();
        _axios.post('/bot/admin/add/' + prefix + '/' + tag, {'new_text': newData}).then(() => {
            getData()
            setNewData('');
            toast.success("L'element a été ajoutée à la liste")
        }).catch((error) => {
            let errorMessage = checkErrorMessage(error)?.message;
            toast.error(errorMessage)
        })
    }

    const deleteText = (last_text) => {
        let _axios = axios_bot();
        _axios.put('/bot/admin/delete/' + prefix + '/' + tag, {last_text}).then(() => {
            getData()
            toast.success("L'element a été supprimée avec succès")
        }).catch((error) => {
            let errorMessage = checkErrorMessage(error)?.message;
            toast.error(errorMessage)
        })
    }

    const upText = (last_text) => {
        let _axios = axios_bot();
        _axios.put('/bot/admin/update/' + prefix + '/' + tag, {
            'new_text': text,
            'last_text': last_text
        }).then(async (resp) => {
            setEdit(false);
            setText('');
            setIndex(null);
            getData()
            toast.success("Text mis à jour")
        }).catch((error) => {
            let errorMessage = checkErrorMessage(error)?.message;
            toast.error(errorMessage)
        })
    }

    const copy = (value) => {
        navigator.clipboard.writeText(value).then(() => {
            setCopied(true)
            toast.success("Le texte à été copié")
        })
    };

    useEffect(() => {

        getData()

        return () => {
            isMounted.current = true
        };
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <Card className="card-stats ml-3 mr-3 mt-2 mb-2" style={{height: 300}}>
            <InputGroup className="no-border">
                <Input placeholder={
                    prefix === 'patterns'
                        ? "Ajouter a nouveau pattern d'ici ..."
                        : "Ajouter un nouveau réponse ici ..."}
                       value={newData}
                       onChange={(e) => changeFields(setNewData, e)}/>
                    <InputGroupText
                        className="cursor-pointer" o
                        nClick={() => newData.length && addData()}>
                        <i className="nc-icon nc-simple-add text-success ml-2"/>
                    </InputGroupText>
            </InputGroup>
            <CardBody className="overflow-auto" style={{height: 200}}>
                {loading ?
                    <div className="text-center overflow-auto">
                        <Spinner animation="grow" size="m" className="mr-2"/>
                    </div>
                    : data.map((value, row) =>
                        <ListItemButton key={'data_' + row} className="m-1 pl-1 pr-1 rounded" style={{width: '100%'}}>
                            <ListItemIcon>
                                {copied && index === row
                                    ? <DoneAllIcon style={{cursor: "context-menu"}}/>
                                    : <TextSnippetIcon style={{cursor: "pointer"}}/>
                                }

                            </ListItemIcon>
                            {!edit ?
                                <ListItemText primary={value} onClick={() => handleEdit(row, value)}/>
                                : edit && index === row ? <InputGroup className="no-border pt-2 mr-2">
                                        <Input placeholder={value}
                                               value={text}
                                               onChange={(e) => changeFields(setText, e)}/>
                                            <InputGroupText onClick={() => {
                                                setEdit(false)
                                                setIndex(null)
                                                setText('')
                                            }}>
                                                <i className="nc-icon nc-simple-remove text-danger ml-2"/>
                                            </InputGroupText>
                                            <InputGroupText onClick={() => text.length && upText(value)}>
                                                <i className="nc-icon nc-send text-success ml-2"/>
                                            </InputGroupText>
                                    </InputGroup>
                                    : <ListItemText primary={value} onClick={() => handleEdit(row, value)}/>}
                            <DeleteSweepIcon
                                sx={{color: "#ED1C24!important"}}
                                onClick={() => deleteText(value)}/>&nbsp;
                            <ModeEditOutlinedIcon sx={{color: "#51cbce!important"}} onClick={() => handleEdit(row, value)}/>
                        </ListItemButton>
                    )}
            </CardBody>
        </Card>
    );
}

export default ManageData;
