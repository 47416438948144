import toast from "react-hot-toast";
import React, {useEffect, useRef, useState} from "react";
import {Button, Card, CardBody, CardHeader, Spinner} from "reactstrap";
import {axios_bot, checkErrorMessage} from "../../../tools/tools";


function BotBtnExecution() {

    const isMounted = useRef(false);
    const [disable, setDisable] = useState(false);
    const [disableTrain, setDisableTrain] = useState(false);

    const train = async () => {
        let _axios = axios_bot();
        setDisableTrain(true)
        _axios.get('/bot/train/training_bot').then(() => {
            setDisableTrain(false)
            toast.success("Votre robot est à jour")
        }).catch((error) => {
            setDisableTrain(false)
            let errorMessage = checkErrorMessage(error)?.message;
            toast.error(errorMessage)
        })
    }

    const addElasticData = async () => {
        setDisable(true)
        let _axios = axios_bot();
        _axios.get('/admin/services/all_services_to_elasticsearch').then(() => {
            setDisable(false)
            toast.success("Données elastic à jour")
        }).catch((error) => {
            setDisable(false)
            let errorMessage = checkErrorMessage(error)?.message;
            toast.error(errorMessage)
        })
    }

    useEffect(() => {

        return () => {
            isMounted.current = true
        };
    }, []);

    return (
        <Card className="card-stats mb-0" style={{height: '85vh'}}>
            <CardHeader>
                <p className="text-center text-uppercase">
                    Paramètres&nbsp;
                </p>
                <hr/>
            </CardHeader>
            <CardBody>
                <Button disabled={disable}
                        className="bg-info"
                        style={{marginTop: '-1px', marginBottom: 15, width: "100%"}}
                        onClick={() => addElasticData()}>
                    Actualiser elasticsearch&nbsp;
                    {disable
                        ? <Spinner animation="grow" size="sm" className="ml-1"/>
                        : <i className="fas fa-sync-alt text-red ml-1"/>}
                </Button>
                <div className="justify-content-center">
                    <Button disabled={disableTrain}
                            className="bg-default"
                            style={{marginTop: '-1px', marginBottom: 15, width: "100%"}}
                            onClick={() => train()}>
                        Enregister et Entraîner&nbsp;
                        {disableTrain
                            ? <Spinner animation="grow" size="sm" className="ml-1"/>
                            : <i className="fas fa-sync-alt text-red ml-1"/>}
                    </Button>
                    <hr/>
                    <div className="text-center">
                        <h5 className="description small text-center">
                            Ce boutton Lance un entrainement.
                        </h5>
                        <hr/>
                        <h5 className="description small text-center">
                            Remplace les données du chatBot sur le serveur avec les nouvelles données.
                        </h5>
                        <hr/>
                        <h5 className="description small text-center">
                            Met à jour le fichier Json sur le cloud.
                        </h5>
                    </div>
                    <hr/>
                </div>
                <div className="p-3 mt-3 bg-dark text-light rounded">
                    <p className="text-center text-uppercase">
                        Activité en cours&nbsp;
                    </p>
                    <hr className="border-light"/>

                    <div className="row m-2 justify-content-center overflow-auto" style={{height: '25vh'}}>
                        <div className="text-truncate">
                            <i className="fas fa-sync-alt mr-2 small"/>
                            <small className="text-uppercase">Aucun activité</small>
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default BotBtnExecution;
