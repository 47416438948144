import axios from "axios";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import Modal from "react-bootstrap/Modal";
import {useSelector} from "react-redux";
import logo from "../../../assets/img/logo.png";
import p_cover from 'assets/img/damir-bosnjak.jpg';
import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, CardFooter, Col, Row} from "reactstrap";

function Profile(props) {

    const [smShow, setSmShow] = useState(false);
    const [currency, setCurrency] = useState('');
    const {profile, condition, user_id, banned, keyActivate} = props
    const countries = useSelector(state => state.global.countries);

    const switchBan = () => {
        axios.put("/admin/users/ban_user_switch/" + user_id.toString()).then(async response => {
            setSmShow(false);
            toast.success("Vous avez changer le status de " + profile.name)
        })
    }

    useEffect(() => {
        let t_country = countries?.filter((c) => c.currencies.code.toLowerCase() === profile.currency.toLowerCase())[0]
        t_country && setCurrency(t_country?.currencies.symbol)
    }, [countries, profile?.currency]);

    return (
        <Card className="card-user m-2">
            <Modal size="sm" show={smShow} aria-labelledby="example-modal-sizes-title-sm">
                <Modal.Body className="text-light">
                    Etes vous sûr de votre action ?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" color="danger" onClick={() => setSmShow(false)}>
                        Non
                    </Button>
                    <Button variant="primary" color="success" onClick={() => switchBan()}>Oui</Button>
                </Modal.Footer>
            </Modal>
            <div className="image">
                <img
                    alt="..."
                    src={profile?.cover_photo ? profile?.cover_photo : p_cover}
                />
            </div>
            <CardBody>
                <div className="author">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                            alt="..."
                            className="avatar border-gray"
                            src={profile?.photo ? profile?.photo : logo}
                        />
                        <h5 className="title">{profile?.name}</h5>
                    </a>
                    <p className="description">{profile?.email}</p>
                </div>
                {keyActivate &&
                    <Row className="border-bottom mb-3">
                        <Col md="4" className="text-break">
                            clé d'activeation
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">{keyActivate}</p>
                        </Col>
                    </Row>}
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        adresse
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-primary text-right">{profile?.address || "pas d'adresse"}</p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        Sexe
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-primary text-right">{profile?.gender || "pas sexe"}</p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        Anniversaire
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-primary text-right">
                            {profile?.birth ? dayjs(profile?.birth).format("DD-MM-YYYY") : "pas de date"}
                        </p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        Pays
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-primary text-right">{profile?.country || "pas de pays"}</p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        Nom d'artiste
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-primary text-right">
                            {profile?.artist_name || "pas de nom d'artiste"}
                        </p>
                    </Col>
                </Row>
                <Row className="border-bottom mb-3">
                    <Col md="4" className="text-break">
                        Telephone
                    </Col>
                    <Col md="8">
                        <p className="font-weight-bold text-primary text-right">
                            {profile?.phone || "pas de numero de telephone"}
                        </p>
                    </Col>
                </Row>
                {condition &&
                    <Row className="border-bottom mb-3">
                        <Col md="4" className="text-break">
                            Politique
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {condition?.refund_policy}
                            </p>
                        </Col>
                    </Row>}
                {condition &&
                    <Row className="border-bottom mb-3">
                        <Col md="4" className="text-break">
                            Transport
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {!condition?.travel_expenses?.from
                                    ? <span className="text-red">Gratuit</span>
                                    : <div>
                                        {condition?.travel_expenses?.from && condition?.travel_expenses?.to
                                            ? <p>entre {condition?.travel_expenses?.from + currency}
                                                jusqu'a {condition?.travel_expenses?.to + currency}
                                            </p>
                                            :
                                            <span
                                                className="text-red">{condition?.travel_expenses?.from + currency}</span>
                                        }
                                    </div>
                                }
                            </p>
                        </Col>
                    </Row>}
                {condition &&
                    <Row className="border-bottom mb-3">
                        <Col md="4" className="text-break">
                            Lundi
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {condition?.monday ? 'disponible' : 'indisponible'}
                            </p>
                        </Col>
                    </Row>}
                {condition &&
                    <Row className="border-bottom mb-3">
                        <Col md="4" className="text-break">
                            Mardi
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {condition?.tuesday ? 'disponible' : 'indisponible'}
                            </p>
                        </Col>
                    </Row>}
                {condition &&
                    <Row className="border-bottom mb-3">
                        <Col md="4" className="text-break">
                            Mercredi
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {condition?.wednesday ? 'disponible' : 'indisponible'}
                            </p>
                        </Col>
                    </Row>}
                {condition &&
                    <Row className="border-bottom mb-3">
                        <Col md="4" className="text-break">
                            Jeudi
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {condition?.thursday ? 'disponible' : 'indisponible'}
                            </p>
                        </Col>
                    </Row>}
                {condition &&
                    <Row className="border-bottom mb-3">
                        <Col md="4" className="text-break">
                            Vendredi
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {condition?.friday ? 'disponible' : 'indisponible'}
                            </p>
                        </Col>
                    </Row>}
                {condition &&
                    <Row className="border-bottom mb-3">
                        <Col md="4" className="text-break">
                            Samedi
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {condition?.saturday ? 'disponible' : 'indisponible'}
                            </p>
                        </Col>
                    </Row>}
                {condition &&
                    <Row className="border-bottom mb-3">
                        <Col md="4" className="text-break">
                            Dimanche
                        </Col>
                        <Col md="8">
                            <p className="font-weight-bold text-primary text-right">
                                {condition?.sunday ? 'disponible' : 'indisponible'}
                            </p>
                        </Col>
                    </Row>}
            </CardBody>
            <CardFooter>
                <hr/>
                <div className="button-container">
                    <Row>
                        <Col className="ml-auto">
                            <Button
                                className="custom-button"
                                color={banned ? "success" : "danger"}
                                onClick={() => setSmShow(true)}
                            >
                                <i
                                    className={`nc-icon ${
                                        banned ? "nc-check-2" : "nc-simple-remove"
                                    } mr-1`}
                                />
                                {banned ? "Donner accès à Kantobiz" : "Bannir de Kantobiz"}
                            </Button>
                        </Col>
                        <Col className="ml-auto">
                            <Button
                                color="primary"
                                className="custom-button"
                                onClick={() => (window.location.href = `mailto:${profile.email}`)}
                            >
                                <i className="nc-icon nc-email-85 mr-1"/>
                                Envoyer un email
                            </Button>
                        </Col>
                    </Row>
                </div>
            </CardFooter>
        </Card>
    );
}

export default Profile;
