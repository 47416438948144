import pako from "pako";
import CryptoJS from "crypto-js";

function generateKey(password) {

    // Compute the SHA256 hash of the given password
    const hashedKey = CryptoJS.SHA256(password);

    // Convert the result into a Uint8Array
    return Uint8Array.from(hashedKey.words.flatMap((word) => [
        (word >> 24) & 0xff,
        (word >> 16) & 0xff,
        (word >> 8) & 0xff,
        word & 0xff
    ])).slice(0, 32); // Return a 32-byte key
}

// Validation and cleanup of a Base64-encoded string
function validateAndCleanBase64(base64) {
    base64 = base64.replace(/[^A-Za-z0-9+/=]/g, "");

    // Validation: attempt decoding
    try {
        atob(base64);
        return base64; // Return the validated and cleaned string
    } catch (error) {
        console.error("The string is not a valid Base64 encoded data:", base64);
        return null;
    }
}

// AES decryption function (CBC + PKCS7)
function decryptAES(encryptedData, key, iv) {
    try {
        // Convert the key and IV into WordArray format (CryptoJS)
        const keyWordArray = CryptoJS.lib.WordArray.create(key);
        const ivWordArray = CryptoJS.lib.WordArray.create(iv);

        // Convert the encrypted data into a WordArray (CryptoJS)
        const encryptedWordArray = CryptoJS.lib.WordArray.create(encryptedData);

        // Perform AES decryption using the key and IV
        const decrypted = CryptoJS.AES.decrypt(
            {ciphertext: encryptedWordArray},
            keyWordArray,
            {
                iv: ivWordArray,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7, // PKCS7 padding standard for AES CBC
            }
        );

        // Convert the decrypted result into a Uint8Array
        const decryptedBytes = Uint8Array.from(
            decrypted.words.flatMap((word) => [
                (word >> 24) & 0xff,
                (word >> 16) & 0xff,
                (word >> 8) & 0xff,
                word & 0xff,
            ]).slice(0, decrypted.sigBytes) // Trim based on the number of significant bytes
        );

        return decryptedBytes;
    } catch (error) {
        console.error("Error during AES decryption:", error.message);
        return null; // Return null on decryption failure
    }
}

function decryptAndInflate(base64Data, password) {
    try {
        // Validate and clean the Base64 string
        const validBase64 = validateAndCleanBase64(base64Data);
        if (!validBase64) {
            throw new Error("Invalid or non-Base64 encoded data provided!");
        }

        // Decode Base64: conversion into a Uint8Array
        const combinedData = Uint8Array.from(atob(validBase64), (char) => char.charCodeAt(0));

        // Split: IV (first 16 bytes) and encrypted data
        const iv = combinedData.slice(0, 16); // IV: first 16 bytes
        const encryptedData = combinedData.slice(16); // Remaining data (encrypted)

        // Generate the AES key based on the password using SHA256
        const key = generateKey(password);

        // Decrypt the data using AES CBC
        const decryptedBytes = decryptAES(encryptedData, key, iv);
        if (!decryptedBytes) {
            throw new Error("AES decryption failed!");
        }

        // Decompress the data using Pako (decoded binary data)
        const decompressedData = pako.inflate(decryptedBytes, {to: "string"});

        return JSON.parse(decompressedData);
    } catch (error) {
        console.error("Error during decryption or decompression:", error.message);
        return null; // Return null on failure
    }
}

// Simple check if a string is Base64 (optional but useful)
function isBase64(str) {
    try {
        return btoa(atob(str)) === str;
    } catch (error) {
        return false;
    }
}

export default function DecryptData(data) {

    if (!isBase64(data)) {
        return data;
    }

    const password = "cU8;*yChZxp57d=sEMFT6YmNLk3)gXQH&a+t.JV#q_P}D{w";

    return decryptAndInflate(data, password)
}
