import axios from "axios";
import * as clipboard from "clipboard-polyfill";
import dayjs from "dayjs";
import React from "react";
import config from "../config/tsconfig.json";
import {InputAdornment, TextField} from "@mui/material";
import ChatMessage from "./ChatMessage";
import DecryptData from "./DecryptData";
import toast from "react-hot-toast";
import ErrorMsg from "./ErrorMsg";

export const changeFields = (setState, e, up_props, dispatch) => {
    let value
    try {
        value = e.target.value;
    } catch {
        value = e
    }

    setState && setState(value);
    if (dispatch) {
        dispatch(up_props(value))
    }
};

export const copy = (index, textToCopy, setRefIndexCopied) => {
    clipboard.writeText(textToCopy).then(() => {
        setRefIndexCopied(index);
        toast.success('Vous avez copier la reference')
    });
}

export const searchArtist = async (e, setLoading, setSearchResult) => {
    let str = e.target.value
    if (str) {
        setLoading(true)
        await axios.get('/admin/users/search_artist/' + str).then((response) => {
            let data = response.data;
            setSearchResult(data)
            setLoading(false)
        })
    }
}

export const axios_bot = () => {
    const instance = axios.create({
        baseURL: !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
            ? config.configs.ServerApiDev
            : config.configs.ServerApi,
        headers: {
            'Language': "fr",
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': "*",
        }
    });

    // Ajouter un intercepteur pour traiter les réponses
    instance.interceptors.response.use(
        response => {
            return { data: DecryptData(response.data) }; // Utilisation de la fonction DecryptData
        },
        error => {
            return Promise.reject(error);
        }
    );

    return instance;
};

export const detectFileType = (url) => {
    // Extract the file extension from the URL
    const extension = url.split('.').pop().toLowerCase();

    // Define a map of common file extensions and their types
    const fileTypes = {
        'jpg': 'image',
        'jpeg': 'image',
        'png': 'image',
        'gif': 'image',
        'bmp': 'image',
        'webp': 'image',
        'svg': 'image',
        'pdf': 'document',
        'doc': 'document',
        'docx': 'document',
        'xls': 'spreadsheet',
        'xlsx': 'spreadsheet',
        'ppt': 'presentation',
        'pptx': 'presentation',
        'txt': 'text',
        'csv': 'spreadsheet',
        'mp4': 'video',
        'mov': 'video',
        'avi': 'video',
        'mkv': 'video',
        'mp3': 'audio',
        'wav': 'audio',
        'ogg': 'audio',
        'zip': 'archive',
        'rar': 'archive',
        '7z': 'archive',
    };

    // Return the file type or "unknown" if not found
    return fileTypes[extension] || 'unknown';
}


export const deleteInObject = (object, special_keys = []) => {
    // Liste des clés standards à supprimer
    const defaultKeys = ['id', 'created_at', 'modified_at'];

    // Suppression des clés standards
    defaultKeys.forEach((key) => {
        try {
            delete object[key];
        } catch (e) {
            // Ignorer les erreurs
        }
    });

    // Suppression des clés spécifiées dans le tableau special_keys
    if (Array.isArray(special_keys)) {
        special_keys.forEach((key) => {
            try {
                delete object[key];
            } catch (e) {
                // Ignorer les erreurs
            }
        });
    }

    return object;
};

export const checkUnity = (unity) => {
    if (unity === 'day') {
        return 'jour(s)'
    } else if (unity === 'hours') {
        return 'heure(s)'
    } else if (unity === 'min') {
        return 'minute(s)'
    } else if (unity === 'sec') {
        return 'seconde(s)'
    }
};

export const checkErrorMessage = (error) => {
    try {
        if (error.response.data.email) {
            return {"error": true, message: error.response.data.email[0]}
        } else {
            let message = JSON.stringify(error.response.data).replace(/"/g, '')
            return ErrorMsg(message)
        }
    } catch (e) {
        return {"error": true, message: "Erreur interne"}
    }
};

export const outputHtPrice = (amount) => {
    let serviceFee = (15 * amount) / 100;
    let artistFee = amount - serviceFee;
    return {serviceFee, artistFee};
}

export const AmountField = (label, value, currency, onChange, helperText, placeholder) => {
    return (
        <TextField
            fullWidth
            size="small"
            label={label}
            value={value}
            variant="filled"
            onChange={onChange}
            placeholder={placeholder}
            helperText={helperText}
            InputProps={{
                startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
            }}
        />
    )
}


export const ITextField= (label, type, value, onChangeFunc, helperText, disabled) => {
    return (
        <TextField
            fullWidth
            hiddenLabel
            size="small"
            value={value}
            variant="filled"
            InputProps={{inputProps: {min: 0}}}
            placeholder={label}
            onChange={onChangeFunc}
            helperText={helperText}
            disabled={disabled}
            type={type ? type : "text"}
            autoComplete={type === "password" ? "new-password" : "off"}
        />
    )
}

export const makeId = (length) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const reservationChatInfo = (reservation, countries) => {
    return <p className="mb-0">
        ----Détails de la réservation----
        <br/>
        <br/>
        Date : <em><strong>{dayjs(reservation?.event_date).format("DD-MM-YYYY")}</strong></em>
        <br/>
        Heure : <em><strong>{dayjs(reservation?.event_date).format("HH:mm A")}</strong></em>
        <br/>
        Évenement : <em><strong>{reservation?.event}</strong></em>
        <br/>
        Addresse : <em><strong>{reservation?.address}</strong></em>
        <br/>
        Politique : <em><strong>{reservation?.refund_policy}</strong></em>
        <br/>
        Montant proposé : <em><strong>{reservation?.total_amount + currencyByAddress(reservation.address, countries)}</strong></em>
        <br/>
    </p>
}

export const switchAnswers = (contactToChat, data, countries) => {

    const dateTags = [
        'event_date',
        'event_time_to_finish',
        'artiste_arrived_hours',
        'first_contact_update_date',
        'first_contact_creation_date',
        'opening_discuss_time_question',
        'event_creation_date_updated'];
    const userInfoTags = ['auditor_info', 'artiste_info'];

    if (userInfoTags.includes(data?.tag)) {
        return <ChatMessage avatar={''} messages={[
            <p className="mb-0">
                ----{data?.result}----
                <br/>
                <br/>
                {data?.tag === 'artiste_info' ? "Nom d'artiste" : 'Nom et Prénom'}&nbsp;:&nbsp;
                <em>
                    <strong>
                        {data?.tag === 'artiste_info'
                            ? data?.end?.artist_name
                            : contactToChat.name + " " + contactToChat.lastname
                        }
                    </strong>
                </em>
                <br/>
                Note(s) : <em><strong>{data?.end?.notes || 0}</strong></em>
                {data?.tag !== 'artiste_info' && <>
                    <br/>
                    Artiste(s) suivi : <em><strong>{data?.end?.followings || 0}</strong></em>
                </>}
                {data?.tag === 'artiste_info' && <>
                    <br/>
                    Nbr fiches : <em><strong>{data?.end?.servicesNbr || 0}</strong></em>
                </>}
            </p>
        ]}/>
    } else if (data?.tag === 'service_info') {
        return <ChatMessage avatar={''} messages={[
            <p className="mb-0">
                {data?.result}&nbsp;:
                <br/>
                <br/>
                Titre de la fiche : <em><strong>{data?.end?.title}</strong></em>
                <br/>
                Nb d'artiste(s) : <em><strong>{data?.end?.number_of_artists}</strong></em>
                <br/>
                Êvénement(s) : <em><strong>{data?.end?.events.join(", ")}</strong></em>
                <br/>
                Ville(s) : <em><strong>
                {data?.end?.cities && data?.end?.cities[0] === 'all'
                    ? "Disponible partout"
                    : data?.end?.cities?.join(", ")}
            </strong></em>
                <br/>
                Style(s) : <em><strong>{data?.end?.thematics.join(", ")}</strong></em>
                <br/>
                Remboursement : <em><strong>{data?.end?.refund_policy}</strong></em>
                <br/>
                Transport : <em><strong>
                {(data?.end?.travel_expenses?.from && data?.end?.travel_expenses?.to)
                    ? "Entre "
                    + data?.end?.travel_expenses?.from.toString() + contactToChat?.payment?.currency
                    + " et "
                    + data?.end?.travel_expenses?.to.toString() + contactToChat?.payment?.currency
                    : data?.end?.travel_expenses?.from.toString() + contactToChat?.payment?.currency}
            </strong></em>
                <br/>
                Montant(À partir de) : <em><strong>{data?.end?.price}{contactToChat?.payment?.currency}</strong></em>
                <br/>
                Dernière modification : <em><strong>
                {dayjs(data?.end?.modified_at).format("DD-MM-YYYY à HH:mm A")}
            </strong></em>
            </p>
        ]}/>
    } else if (data?.tag === 'reservation_info') {
        return <ChatMessage avatar={''} messages={[reservationChatInfo(contactToChat?.reservation, countries)]}/>
    }
    return <ChatMessage avatar={''} messages={[
        <p className="mb-0">
            <strong hidden={!data?.start}>
                {data?.start}&nbsp;
            </strong>
            {data?.result}
            <strong hidden={!data?.end} className="text-lowercase">
                &nbsp;{dateTags.includes(data?.tag)
                ? dayjs(data?.end).format("DD-MM-YYYY à HH:mm A")
                : data?.tag === 'invoice'
                    ? <a href={data.end} target="_blank" rel="noreferrer">
                        Voir le lien
                    </a> : data?.end}.
            </strong>
        </p>
    ]}/>
}

export const currencyByAddress = (addressOfEvent, countryAllowed) => {
    let cur, countryTmp;
    try {
        let tmp = addressOfEvent?.split(', ')
        countryTmp = tmp[tmp?.length - 1]
    } catch {
        countryTmp = addressOfEvent
    }
    /* eslint-disable-next-line array-callback-return */
    countryAllowed.map((tmpCountry) => {
        if (countryTmp.toLowerCase() === tmpCountry['name'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            || countryTmp.toLowerCase() === tmpCountry['nativeName'].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
            cur = tmpCountry?.currencies?.symbol
        }
    });
    return cur
}
