import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {Row, Col, Spinner} from "reactstrap";
import CertificationDetail from "./certificationDetail";
import CertificationList from "./certificationList";
import CertificationModal from "./certificationModal";

function Certifications() {

    const isMounted = useRef(false);
    const [certifications, setCertifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openModal, setOpenModal] = useState(null);
    const [selectedCertification, setSelectedCertification] = useState(null); // Pour le modal

    const fetchCertifications = async () => {
        setLoading(true);
        try {
            const response = await axios.get("/admin/certifications/last"); // Remplacez avec votre endpoint
            let new_list = response.data;
            if (new_list?.length) {
                setSelectedCertification(new_list[0])
            }
            setCertifications(response.data);
        } catch (error) {
            toast.error("Erreur lors du chargement des certifications.")
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        !isMounted.current && fetchCertifications().then(r => null);

        return () => {
            isMounted.current = true;
        };
    }, []);

    const tOpenModal = (certification) => {
        setOpenModal(certification);
    };

    const closeModal = () => {
        setOpenModal(null);
    };

    const handleCertificationUpdate = (updatedCertification) => {
        setCertifications((prevCertifications) =>
            prevCertifications.map((certification) =>
                certification.id === updatedCertification.id ? updatedCertification : certification
            )
        );

        setSelectedCertification(updatedCertification);
    };

    return (
        <div className="content">
            {loading ? (
                <div className="text-center mt-5">
                    <Spinner color="primary"/>
                    <p>Chargement des certifications...</p>
                </div>
            ) : (
                <Row>
                    <Col md="4">
                        <CertificationList
                            tOpenModal={tOpenModal}
                            certifications={certifications}
                            selectedCertification={selectedCertification}
                            onSelectCertification={setSelectedCertification}
                        />
                    </Col>
                    <Col md="8">
                        <CertificationDetail certification={selectedCertification}/>
                    </Col>
                </Row>
            )}

            {/* Modal pour les détails */}
            {openModal && (
                <CertificationModal
                    closeModal={closeModal}
                    selectedCertification={openModal}
                    updateCertification={handleCertificationUpdate}/>
            )}
        </div>
    );
}

export default Certifications;
