import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import {Card, CardBody, CardFooter, CardHeader, CardTitle, Input, Spinner} from "reactstrap";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const options = {
    plugins: {
        legend: {display: false},
    },
};

const reservations = "Réservations";
const contactCards = "Prises de contact";
const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function ReservationDiagrams() {

    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const [tag, setTag] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentYear, setCurrentYear] = useState(1900 + new Date().getYear());

    const createChart = (acceptedData, declinedData) => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const ctx = chartRef.current.getContext("2d");
        chartInstance.current = new ChartJS(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'Prise de contact acceptée',
                        data: acceptedData,
                        fill: false,
                        borderColor: 'green',
                        tension: 0.1,
                    },
                    {
                        label: 'Prise de contact refusée',
                        data: declinedData,
                        fill: false,
                        borderColor: 'red',
                        tension: 0.1,
                    },
                ],
            },
            options,
        });
    };

    const changeYear = async (nbr) => {
        const newYear = currentYear + nbr;
        await setCurrentYear(newYear);
        if (tag === reservations) {
            await checkRYear(newYear);
        } else {
            await checkCYear(newYear);
        }
    }

    const checkRYear = async (year) => {
        setTag(reservations);
        setLoading(true);
        const newYear = year || currentYear;
        await axios.get('/admin/reservation/years_stat/' + newYear).then((response) => {
            const data = response.data;
            const declinedData = data['reservation_declined'];
            const acceptedData = data['reservation_accepted'];
            createChart(acceptedData, declinedData);
            setLoading(false);
        });
    }

    const checkCYear = async (year) => {
        setTag(contactCards);
        setLoading(true);
        const newYear = year || currentYear;
        await axios.get('/admin/contact_cards/years_stat/' + newYear).then((response) => {
            const data = response.data;
            const declinedData = data['contact_card_declined'];
            const acceptedData = data['contact_card_accepted'];
            createChart(acceptedData, declinedData);
            setLoading(false);
        });
    }

    useEffect(() => {
        checkCYear().then(() => null);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <Card className="card-chart">
            <CardHeader>
                <CardTitle tag="h5">
                    Diagramme des
                    <div className="col-4 p-0 my-2">
                        <Input
                            type="select"
                            onChange={(e) =>
                                e.target.value === reservations ? checkRYear() : checkCYear()
                            }
                        >
                            <option value={contactCards} defaultChecked>
                                {contactCards}</option>

                            <option value={reservations}>
                                {reservations}
                            </option>
                        </Input>
                    </div>
                </CardTitle>
                <p className="card-category">Statistique de l'année {currentYear}</p>
            </CardHeader>
            <CardBody className="card-diagram-body">
                <canvas ref={chartRef} width={400} height={100}></canvas>
                {/* Canvas pour Chart.js */}
            </CardBody>
            <CardFooter>
                <div className="chart-legend">
                    <i className="fa fa-circle text-success m-1"/> Acceptée
                    <i className="fa fa-circle text-danger m-1"/> Déclinée
                </div>
                <hr/>
                <div className="stats row justify-content-between pl-3 pr-3">
                    <div className="text-dark">
                        <i
                            className="nc-icon nc-minimal-left cursor-pointer"
                            onClick={() => changeYear(-1)}
                        />
                        {loading ? (
                            <Spinner animation="grow" size="sm" color="secondary"/>
                        ) : (
                            <i className="fas fa-sync-alt" onClick={() => tag === reservations ? checkRYear() : checkCYear()}/>
                        )}
                        <i
                            className="nc-icon nc-minimal-right cursor-pointer"
                            onClick={() => changeYear(+1)}
                        />
                    </div>
                </div>
            </CardFooter>
        </Card>
    );
}

export default ReservationDiagrams;
