import React, {useEffect, useRef} from "react";
import {Col, Row, Card} from "reactstrap";
import BotBtnExecution from "../components/Main/chatBot/botBtnExecution";
import ChatData from "../components/Main/chatBot/chatData";
import Main from "../components/Main/chatBot/main";

function ChatBot() {

    const isMounted = useRef(false);

    useEffect(() => {

        return () => {
            isMounted.current = true
        };
    }, []);

    return (
        <div className="content">
            <Row>
                <Col md="5">
                    <Card className="card-user">
                        <ChatData />
                    </Card>
                </Col>
                <Col md="4">
                    <Card className="card-user">
                        <Main />
                    </Card>
                </Col>
                <Col md="3">
                    <Card className="card-user">
                        <BotBtnExecution />
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ChatBot;
