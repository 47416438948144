import {useEffect, useRef} from "react";
import PerfectScrollbar from "perfect-scrollbar";
import {Route, Routes, useLocation} from "react-router-dom";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";

let ps;

function Dashboard(props) {

    const mainPanel = useRef();
    const location = useLocation();

    useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(mainPanel.current);
            document.body.classList.toggle("perfect-scrollbar-on");
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.body.classList.toggle("perfect-scrollbar-on");
            }
        };
    });

    useEffect(() => {
        mainPanel.current.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [location]);

    return (
        <div className="wrapper">
            <Sidebar{...props} routes={routes}/>
            <div className="main-panel" ref={mainPanel}>
                <DemoNavbar {...props} />
                <Routes>
                    {routes.map((prop, key) => {
                        return (
                            <Route
                                key={key}
                                path={prop.path}
                                element={<prop.component />}
                            />
                        );
                    })}
                </Routes>
                <Footer fluid />
            </div>
        </div>
    );
}

export default Dashboard;
