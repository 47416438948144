import axios from "axios";
import React, {useEffect, useRef, useState} from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
} from "chart.js";
import {Card, CardBody, CardFooter, CardHeader, CardTitle, Spinner} from "reactstrap";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const options = {
    plugins: {
        legend: {display: false},
        tooltip: {enabled: false},
    },
    scales: {
        y: {
            ticks: {
                color: "#9f9f9f",
                beginAtZero: false,
                maxTicksLimit: 5,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
        x: {
            barPercentage: 1.6,
            grid: {
                drawBorder: false,
                display: false,
            },
            ticks: {
                padding: 20,
                color: "#9f9f9f",
            },
        },
    },
};

const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function UserBehaviors(callback, deps) {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const [loading, setLoading] = useState(true);
    const [currentYear, setCurrentYear] = useState(1900 + new Date().getYear());

    const createChart = (stat) => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const ctx = chartRef.current.getContext("2d");
        chartInstance.current = new ChartJS(ctx, {
            type: "line",
            data: {
                labels: labels,
                datasets: [
                    {
                        label: "Inscriptions",
                        borderColor: "#6bd098",
                        backgroundColor: "#6bd098",
                        fill: true,
                        data: stat,
                        tension: 0.1,
                    },
                ],
            },
            options: options,
        });
    };

    const changeYear = async (nbr) => {
        const newYear = currentYear + nbr;
        await setCurrentYear(newYear);
        await checkUserBehaviorByYear(newYear);
    }

    const checkUserBehaviorByYear = async (year) => {
        setLoading(true);
        const newYear = year || currentYear;
        await axios.get(`/admin/users/years_stat/${newYear}`).then((response) => {
            setCurrentYear(newYear)
            createChart(response.data);
            setLoading(false);
        });
    }

    useEffect(() => {
        checkUserBehaviorByYear().then(() => null);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    return (
        <Card className="card-chart">
            <CardHeader>
                <CardTitle tag="h5">
                    Entrée des utilisateurs
                </CardTitle>
                <p className="card-category">
                    Statistique de l'année {currentYear}
                </p>
            </CardHeader>
            <CardBody className="card-diagram-body">
                <canvas ref={chartRef} width={400} height={100}></canvas>
            </CardBody>
            <CardFooter>
                <div className="chart-legend">
                    <i className="fa fa-circle text-success m-1"/> Inscription
                </div>
                <hr/>
                <div className="stats row justify-content-between pl-3 pr-3">
                    <div className="text-uppercase cursor-pointer">

                    </div>
                    <div className="text-dark">
                        <i
                            className="nc-icon nc-minimal-left cursor-pointer"
                            onClick={() => changeYear(-1)}
                        />
                        {loading ? (
                            <Spinner animation="grow" size="sm" color="secondary"/>
                        ) : (
                            <i className="fas fa-sync-alt"
                               onClick={() => checkUserBehaviorByYear()}/>
                        )}
                        <i
                            className="nc-icon nc-minimal-right cursor-pointer"
                            onClick={() => changeYear(1)}
                        />
                    </div>
                </div>
            </CardFooter>
        </Card>
    );
}

export default UserBehaviors;
